<template>
    <a v-if="$helper.userCapability('delete',$route)" @click="modalShow = true" class="custom-btn delete-btn"
       title="Delete item">
        <bootstrap-icon :icon="icon" size="1x"/>
        <b-modal
            v-model="modalShow"
            modal-class="delete-dialog"
            cancel-title="Cancel"
            ok-title="Delete"
            @ok="confirmDelete"

        >
            <h3>{{ $t("messages.confirmDelete") }}</h3>
        </b-modal>
    </a>
</template>

<script>

export default {
    name: "DeleteBtn",
    props: {
        icon: {type: String, default: 'trash'}
    },
    data() {
        return {
            modalShow: false
        };
    },
    methods: {
        confirmDelete() {
            this.$emit("pressDelete");
        },
    },
};
</script>
