<template>
    <div>
        <title-bar :title="$t(title)"/>
        <div v-if="rows" class="custom-table">
            <advanced-filter
                :fields-array="filterFields"
                :action="showFilterModal"
                @clearAction="showFilterModal = false"
                @paramsChange="setAdvancedParams"
            />
            <table-actions
                :actions="['search', 'addBtn', 'perPage', 'advancedFilter']"
                :addBtnText="$t('messages.invoiceCreate')"
                :addBtnLink="$helper.getAddUrl(addUrl)"
                @onSearchInput="inputChange"
                @perPageChange="perPageChange"
                @filterChange="showFilterModal = true"
                :defaultPerPage="perPage"
            />
            <table-lite
                :is-slot-mode="true"
                :is-loading="isLoading"
                :columns="columns"
                :rows="rows"
                :sortable="sortable"
                @do-search="setSort"
                @is-finished="isLoading = false"
            >
                <template v-slot:invoiceNumber="data">
                    {{ data.value.invoiceNumber }}
                </template>
                <template v-slot:dueDate="data">
                    {{ $helper.formatDate(data.value.dueDate) }}
                </template>
                <template v-slot:dateIssued="data">
                    {{ $helper.formatDate(data.value.dateIssued) }}
                </template>
                <template v-slot:customer="data">
                  <span v-if="'customer' in data.value">
                    {{ data.value.customer }}
                  </span>
                </template>
                <template v-slot:amount="data">
                    {{ data.value.amount }}
                </template>
                <template v-slot:supplyOfGoodOrServices="data">
                    {{ $helper.formatDate(data.value.supplyOfGoodOrServices) }}
                </template>
                <template v-slot:paymentInterval="data">
                    <b-badge :variant="statusClass(data.value.paymentInterval, data.value.paid)">
                        {{ $helper.formatDate(data.value.paymentInterval) }}
                    </b-badge>
                </template>
                <template v-slot:paid="data">
                    <b-badge :variant="data.value.paid ? 'success':'danger'">
                        {{ data.value.paid ? "Yes" : "No" }}
                    </b-badge>
                </template>
                <template v-slot:outsourced="data">
                    <b-badge :variant="data.value.outsourced ? 'success':'danger'">
                        {{ data.value.outsourced ? "Yes" : "No" }}
                    </b-badge>
                </template>
                <template v-slot:paidAt="data">
                    {{ $helper.formatDate(data.value.paidAt) }}
                </template>
                <template v-slot:station="data">
                    {{ data.value.station.name }}
                </template>
                <template v-slot:category="data">
                    <span v-if="'category' in data.value">
                        {{ data.value.category.name }}
                    </span>
                </template>
                <template v-slot:description="data">
                    {{ data.value.description }}
                </template>
                <template v-slot:markAsPaidBy="data">
                  <span v-if="'markAsPaidBy' in data.value">
                    {{
                          data.value.markAsPaidBy.givenName +
                          " " +
                          data.value.markAsPaidBy.familyName
                      }}
                  </span>
                </template>
                <template v-slot:approvedBy="data">
                  <span v-if="'approvedBy' in data.value">
                    {{
                          data.value.approvedBy.givenName +
                          " " +
                          data.value.approvedBy.familyName
                      }}
                  </span>
                </template>
                <template v-slot:file="data">
                    <view-btn v-if="'url' in data.value" :path="data.value.url"/>
                </template>
                <template v-slot:actions="data">
                    <div class="just-buttons">
                        <edit-btn
                            :path="$helper.getEditUrl(addUrl, data.value.id)"
                        />
                        <delete-btn @pressDelete="deleteInvoice(data.value['@id'])"/>
                    </div>
                </template>
            </table-lite>
            <table-pagination
                v-model="currentPage"
                :total="pages"
                :per-page="perPage"
                @input="onChangePage"
            />
        </div>
    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import TableActions from "@/components/Table/TableActions.vue";
import EditBtn from "@/components/Buttons/EditBtn.vue";
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";
import ViewBtn from "@/components/Buttons/ViewBtn.vue";
import ResourceUtils from "@/mixins/ResourceUtils";
import AdvancedFilter from "@/components/Table/AdvancedFilter.vue";
import moment from "moment";

export default {
    name: "AdminIncomingInvoicesListing",
    components: {
        TableLite,
        TablePagination,
        TableActions,
        EditBtn,
        DeleteBtn,
        ViewBtn,
        TitleBar,
        AdvancedFilter,
    },
    mixins: [ResourceUtils],
    data() {
        return {
            apiUrl: this.$store.state.apiUrl,
            search: "",
            isLoading: false,
            rows: null,
            perPage: this.$store.state.defaultPerPage,
            currentPage: 1,
            pages: 0,
            showFilterModal: false,
            filterFields: [
                {
                    label: 'forms.dueDate',
                    name: "dueDate",
                    value: null,
                    type: "range"
                },
                {
                    label: 'forms.paymentInterval',
                    name: "paymentInterval",
                    value: null,
                    type: "range",
                },
                {
                    label: 'forms.company',
                    name: "customer",
                    value: null,
                    type: "text"
                },
            ],
            advancedParams: [],
            title: "navigations.incomingInvoices",
            type: "INCOMING",
            addUrl: "invoices/incoming",
            sortable: {
                order: "createdAt",
                sort: "desc",
            },
            columns: [
                {
                    label: '#',
                    field: "id",
                    sortable: true,
                },
                {
                    label: this.$t("forms.number"),
                    field: "invoiceNumber",
                    sortable: true,
                },
                {
                    label: this.$t("forms.dateIssued"),
                    field: "dateIssued",
                    sortable: true,
                },
                {
                    label: this.$t("forms.company"),
                    field: "customer",
                    sortable: true,
                },
                {
                    label: this.$t("forms.amount"),
                    field: "amount",
                    sortable: true,
                },
                {
                    label: this.$t("forms.currency"),
                    field: "currency",
                    sortable: true,
                },
                {
                    label: this.$t("forms.category"),
                    field: "category",
                    sortable: true,
                },
                {
                    label: this.$t("forms.description"),
                    field: "description",
                    sortable: true,
                },
                {
                    label: this.$t("forms.markAsPaidBy"),
                    field: "markAsPaidBy",
                    sortable: true,
                },
                {
                    label: this.$t("forms.approvedBy"),
                    field: "approvedBy",
                    sortable: true,
                },
                {
                    label: this.$t("forms.dueDate"),
                    field: "dueDate",
                    sortable: true,
                },
                {
                    label: this.$t("forms.paidAt"),
                    field: "paidAt",
                    sortable: true,
                },
                {
                    label: this.$t("forms.paymentInterval"),
                    field: "paymentInterval",
                    sortable: true,
                },
                {
                    label: this.$t("forms.paid"),
                    field: "paid",
                    sortable: true,
                },
                {
                    label: "Outsourced",
                    field: "outsourced",
                    sortable: true,
                },
                {
                    label: this.$t("forms.file"),
                    field: "file",
                    sortable: false,
                },
                {
                    label: this.$t("forms.actions"),
                    field: "actions",
                    sortable: false,
                    width: "80px",
                },
            ]
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            const params = {
                itemsPerPage: this.perPage,
                page: this.currentPage,
                type: this.type,
            };
            params["order[" + this.sortable.order + "]"] = this.sortable.sort;
            if (this.search && this.search.length) {
                params.search = this.search;
            }
            this.advancedParams.forEach(element => {
                if (element.value !== '' && element.value !== null) {
                    params[element.name] = element.value
                }
            });
            this.$Invoices.getCollection({params}, 'invoice_list').then((response) => {
                this.pages = response.data["hydra:totalItems"];
                this.rows = response.data["hydra:member"];
            });
        },
        setSort(offset, limit, order, sort) {
            this.sortable.sort = sort;
            this.sortable.order = order;
            if (order === "branch" || order === "station" || order === "category") {
                this.sortable.order = order + ".name";
            }
            if (order === "employee") {
                this.sortable.order = order + ".familyName";
            }
            this.load();
        },
        onChangePage(page) {
            this.currentPage = page;
            this.load();
        },
        setAdvancedParams(data) {
            this.advancedParams = data
            this.load()
        },
        deleteInvoice(url) {
            this.deleteByUrl(
                this.$Invoices,
                url,
                this.$t("messages.invoiceDeleted"),
                null,
                this.load,
                this.error
            );
        },
        statusClass(interval, paid) {
            let className = "success";
            if (moment(interval).format('DD.MM.YYYY') === moment(new Date()).format('DD.MM.YYYY') && !paid) {
                className = "warning";
            }
            return className;
        },
        inputChange(value) {
            this.currentPage = 1;
            this.search = value;
            this.load();
        },
        onPageChange() {
            this.load();
        },
        perPageChange(num) {
            this.currentPage = 1;
            this.perPage = num;
            this.load();
        },
        error() {
            this.$emit("clearAction");
        },
    },
};
</script>

